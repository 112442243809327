﻿/*
  mall-alerts.js
  Scripts for Mall Alerts on mall level pages
  Dev: Dean Weber - need more info from him
*/

// include cookie tools for reading and writing
const cookieTools = require('../../ga/scripts/partials/cookies');

(function () {
  // alert functions
  const mallAlertTools = (function () {
    const cName = 'mallalerts';
    let mallAlerts = undefined;

    // get alerts from cookies
    function getAlerts() {
      if(mallAlerts === undefined && cookieTools.read(cName) !== undefined) {
        mallAlerts = JSON.parse(cookieTools.read(cName));
      }
      return mallAlerts;
    }

    // check if date is valid
    function isValidDate(d) {
      return (Object.prototype.toString.call(d) === '[object Date]' && !isNaN(d.getTime()));
    }

    // ? - check with dean
    function toDate(dateParm) {
      let date;
      switch (typeof dateParm) {
        case 'number':
          date = new Date(dateParm * 1000);
          return isValidDate(date) ? date : undefined;
        case 'object':
          return isValidDate(dateParm) ? dateParm : undefined;
        case 'string':
          date = Date.parse(dateParm);
          return isValidDate(date) ? date : undefined;
        default:
          return undefined;
      }
    }

    // ? - check with dean
    function isValidDateParm(d) {
      let isValid = false;
      if(d !== undefined) {
        const date = toDate(d);
        isValid = date !== undefined;
      }
      return isValid;
    }

    // are valid parms? - check with dean
    function areValidParms(id, date) {
      const isValidId = !(id === undefined || id === '');
      const isValidDateCheck = isValidDateParm(date);
      const isValid = isValidId && isValidDateCheck;
      return isValid;
    }

    // make key for alert
    function createKey(id, endDateInSecs) {
      const key = '' + id + '_' + endDateInSecs;
      return key;
    }

    // init
    function initAlerts(id, endDate) {
      let c = getAlerts();
      if(c === undefined && areValidParms(id, endDate)) {
        const date = toDate(endDate);
        if(date !== undefined) {
          const endDateInSecs = Date.parse(date) / 1000;
          const key = createKey(id, endDateInSecs);
          c = {};
          c[key] = endDateInSecs;
          cookieTools.write(cName, JSON.stringify(c));
        }
      }
      mallAlerts = c;
      return c;
    }

    // save alert
    function saveAlert(id, endDate) {
      if(!areValidParms(id, endDate)) {
        return;
      }
      const date = toDate(endDate);
      if(date !== undefined) {
        const endDateInSecs = Date.parse(date) / 1000;
        const key = createKey(id, endDateInSecs);
        let c = getAlerts();
        if(c === undefined || typeof c !== 'object') {
          c = {};
        }
        c[key] = endDateInSecs;
        cookieTools.write(cName, JSON.stringify(c));
        mallAlerts = c;
      }
    }

    // get alert val
    function getAlertValue(id, endDate) {
      if(!areValidParms(id, endDate)) {
        return null;
      }

      const date = toDate(endDate);
      if(date === undefined) {
        return undefined;
      }

      const endDateInSecs = Date.parse(date) / 1000;
      const key = createKey(id, endDateInSecs);
      const c = getAlerts();
      const alertValue = c !== undefined && typeof c === 'object' ? c[key] : undefined;

      return alertValue;
    }

    // clean alerts
    function purgeAlerts() {
      let endDateInSecs;
      let deleted = 0;
      const c = getAlerts();

      if(c !== undefined) {
        const nowInSecs = Date.parse(new Date().toUTCString()) / 1000;
        let key = '';
        for(key of Object.keys(c)) {
          if(c.hasOwnProperty(key)) {
            const keytokens = key.split('_', 2);
            if(keytokens.length === 2) {
              endDateInSecs = keytokens[1];
              if(nowInSecs > endDateInSecs) {
                if(delete c[key]) {
                  deleted++;
                }
              }
            }
          }
        }
        if(deleted > 0) {
          cookieTools.write(cName, JSON.stringify(c));
        }
        mallAlerts = c;
      }
    }

    return {
      init: initAlerts,
      get: getAlertValue,
      set: saveAlert,
      getAlert: getAlerts,
      purge: purgeAlerts,
      isValidParms: areValidParms
    };
  })();

  // hides the mall alert - applied to X button in the alert html on the view
  const dismissMallAlert = function (id, endDate) {
    mallAlertTools.init();
    mallAlertTools.set(id, new Date(endDate));
    const a = mallAlertTools.get(id, new Date(endDate));
    if(a !== undefined) {
      document.querySelector('.mall-alert[data-id=\'' + id + '\']').classList.add('hidden-xs-up');
      document.getElementById('simon').classList.remove('mall-alert-active');
      if(document.querySelector('.admin-view-publish')) {
        document.querySelector('.admin-view-publish').classList.add('avp-noalert');
      }
    }
  };

  // show mall alert
  const showMallAlert = function (id, endDate) {
    mallAlertTools.init();
    const a = mallAlertTools.get(id, new Date(endDate));
    if(a === undefined && id !== 0 && id !== '0' && id !== '') {
      document.querySelector('.mall-alert[data-id=\'' + id + '\']').classList.remove('hidden-xs-up');
      document.getElementById('simon').classList.add('mall-alert-active');
      if(document.querySelector('.admin-view-publish')) {
        document.querySelector('.admin-view-publish').classList.remove('avp-noalert');
      }
    }
  };

  // Testing the ability to grab the mall-alert height and changing said value in the variables.css
  const setActiveMallAlertHeight = function () {
    const e = document.querySelector('.mall-alert viewport-xxl-containers');
    const h = e.offsetHeight;
    const h1 = h + 'px';

    e.style.setProperty('--mall-alert-height', h1);
    // alert("The value is: " + h + "and: " + h1);
  };

  // show mall alerts that are active
  const showActiveMallAlerts = function () {
    const alerts = document.querySelectorAll('.mall-alert');
    for(let i = 0; i < alerts.length; i++) {
      const id = alerts[i].attributes['data-id'].value;
      const endDate = alerts[i].attributes['data-enddate'].value;
      showMallAlert(id, endDate);
    }
  };

  // add to window to use in view
  window.dismissMallAlert = dismissMallAlert;

  // event listeners: one for selects and one for the switch
  document.addEventListener('DOMContentLoaded', function () {
    mallAlertTools.purge();
    showActiveMallAlerts();
    // setActiveMallAlertHeight();
  }, false);
})();
